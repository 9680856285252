(function () {
    'use strict';

    angular.module('adminApp')
        .config(adminAppRoutesConfig);

    adminAppRoutesConfig.$inject = ['$stateProvider', '$urlRouterProvider', 'routesConstant'];

    function adminAppRoutesConfig($stateProvider, $urlRouterProvider, routesConstant) {

        var ADMIN = routesConstant.ADMIN;

        var makeResolve = function () {
            return {
                allPermissions: ['CommonService', function (CommonService) {
                    var promise = CommonService.getAllPermissions()
                        .then(function (types) {
                            return loadPermissions(types)
                        });
                    angular.module('rolesConfig').value("allPermissions", promise);
                    return promise;
                }],
                allRoles: ['CommonService', function (CommonService) {
                    var promise = CommonService.getAllRoles()
                        .then(function (types) {
                            return loadRoles(types)
                        });
                    angular.module('rolesConfig').value("allRoles", promise);
                    return promise;
                }]
            }
        };

        $stateProvider
            .state(ADMIN.MAIN.stateName, {
                url: ADMIN.MAIN.url,
                controller: ['$state', 'routesConstant', function ($state, routesConstant) {
                    if ($state.current.name === ADMIN.MAIN.stateName) {
                        $state.go(routesConstant.ADMIN.DASHBOARD.stateName);
                    }
                }]
            })
            .state(ADMIN.DASHBOARD.stateName, {
                url: ADMIN.DASHBOARD.url,
                controller: 'AdminDashboardController',
                templateUrl: '/static/templates/admin/dashboard.html',
                resolve: makeResolve()
            })
            .state(ADMIN.ORGANIZATIONS.stateName, {
                url: ADMIN.ORGANIZATIONS.url,
                views: {
                    "content": {
                        controller: 'OrganizationsTabCtrl',
                        templateUrl: '/static/templates/admin/organizations-tab.html'
                    }
                },
                resolve: makeResolve()
            })
            .state(ADMIN.ORGANIZATIONS_VIEW.stateName, {
                url: ADMIN.ORGANIZATIONS_VIEW.url,
                views: {
                    "content": {
                        controller: 'ViewOrganizationCtrl',
                        templateUrl: '/static/templates/admin/view-organization.html'
                    }
                },
                resolve: makeResolve()
            })
            .state(ADMIN.USER_LOGGING.stateName, {
                url: ADMIN.USER_LOGGING.url,
                views: {
                    "content": {
                        controller: 'ViewUserLoggingCtrl',
                        templateUrl: '/static/templates/admin/view-organization-user-logging.html'
                    }
                }
            })
            .state(ADMIN.PROPERTIES.stateName, {
                url: ADMIN.PROPERTIES.URL,
                views: {
                    "content": {
                        controller: 'PropertiesTabCtrl',
                        templateUrl: '/static/templates/admin/properties-tab.html'
                    }
                },
                resolve: makeResolve()
            })
            .state(ADMIN.SOFTWARE.stateName, {
                url: ADMIN.SOFTWARE.url,
                views: {
                    "content": {
                        templateUrl: '/static/templates/admin/dashboard-software.html',
                        controller: "SoftwareUpdateController"
                    }
                },
                resolve: makeResolve()
            })
            .state(ADMIN.USERS.stateName, {
                url: ADMIN.USERS.url,
                views: {
                    "content": {
                        templateUrl: '/static/templates/admin/dashboard-users.html',
                        controller: "manageUsersCtrl"
                    }
                },
                resolve: makeResolve()
            })
            .state(ADMIN.NOTIFICATION_REGISTRATION.stateName, {
                url: ADMIN.NOTIFICATION_REGISTRATION.url,
                views: {
                    "content": {
                        templateUrl: '/static/templates/admin/dashboard-notification-registration.html',
                        controller: "NotificationRegistration"
                    }
                },
                resolve: makeResolve()
            })
            .state(ADMIN.MESSAGES.stateName, {
                url: ADMIN.MESSAGES.url,
                views: {
                    "content": {
                        templateUrl: '/static/templates/admin/dashboard-messages.html',
                        controller: "manageMessagesCtrl"
                    }
                },
                resolve: makeResolve()
            })
            .state(ADMIN.LOCK.stateName, {
                url: ADMIN.LOCK.url,
                views: {
                    "content": {
                        templateUrl: '/static/templates/admin/dashboard-lock.html',
                        controller: "manageSystemLockCtrl"
                    }
                },
                resolve: makeResolve()
            })
            .state(ADMIN.LICENSES.stateName, {
                url: ADMIN.LICENSES.url,
                views: {
                    "content": {
                        templateUrl: '/static/templates/admin/manageLicenses.html',
                        controller: "ManageLicensesCtrl"
                    }
                },
                resolve: makeResolve()
            })
            .state(ADMIN.VENDORS.stateName, {
                url: ADMIN.VENDORS.url,
                views: {
                    "content": {
                        templateUrl: '/static/templates/admin/vendors.html',
                        controller: 'VendorController'
                    }
                },
                resolve: angular.extend({
                    vendors: ['aerosAdminApi', function (aerosAdminApi) {
                        return aerosAdminApi.vendorApi.list();
                    }]
                }, makeResolve())
            })
            .state(ADMIN.FEATURES.stateName, {
                url: ADMIN.FEATURES.url,
                views: {
                    "content": {
                        templateUrl: '/static/templates/admin/features.html',
                        controller: 'FeatureController'
                    }
                },
                resolve: angular.extend({
                    features: ['aerosAdminApi', function (aerosAdminApi) {
                        return aerosAdminApi.featureApi.list();
                    }]
                }, makeResolve())
            })
            .state(ADMIN.VAULT.stateName, {
                url: ADMIN.VAULT.url,
                views: {
                    "content": {
                        template: '<afl-vault-page></afl-vault-page>',
                    }
                }
            });
    }
})();
